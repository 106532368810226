<template>
  <div class="course">
    <banner></banner>
    <div class="site-content animate">
      <main class="site-main">
        <article class="hentry">
          <!-- 文章头部 -->
          <header class="entry-header">
            <!-- 标题输出 -->
            <h1 class="entry-title">发展历程</h1>
            <hr />
          </header>
          <!-- 正文输出 -->
          <div class="entry-content">
            <h4 id="公元1762年">公元1762年</h4>
            <img class="courseImg" src="https://s4.ax1x.com/2022/02/23/bPp5EF.jpg" alt="">
            <p>公元1762年，祖先因善举习得柏林禅寺做饼的手艺，赠送月饼模具，诞生赵州饼的原型“自来红”月饼</p>
            <hr />
            <h4 id="1865年">1865年</h4>
            <img class="courseImg" src="https://s4.ax1x.com/2022/02/23/bPCBWj.jpg" alt="">
            <p>1865年，从西湘洋村搬到了大石桥村，在此地大卖，开始得名“赵州饼”</p>
            <hr />
            <h4 id="1959年">1959年</h4>
            <img class="courseImg" src="https://s4.ax1x.com/2022/02/24/bF94qx.jpg" alt="">
            <p>1959年，外祖父退伍复员，开始操办赵州饼生产</p>
            <hr />
            <h4 id="1991年">1991年</h4>
            <img class="courseImg" src="https://s4.ax1x.com/2022/02/24/bF9IZ6.jpg" alt="">
            <p>1991年，赵县食品厂改制停产，外祖父根据市场需求，创办了“桥头第一家”个体糕饼店</p>
            <hr />
            <h4 id="2019年">2019年</h4>
            <p>2019年，自媒体盛行，被人发现，在抖音和今日头条盛传，店里生意也爆火</p>
            <hr />
            <h4 id="2021年">2021年</h4>
            <p>2021年，增加线上销售渠道，自建厂房扩大生产力，满足市场需求</p>
          </div>
        </article>
      </main>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
export default {
  name: "course",
  data() {
    return {
      showDonate: false,
      comments: [],
      menus: [],
    };
  },
  components: {
    Banner,
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style scoped lang="less">
.site-content {
  position: relative;
  .site-main {
    padding: 80px 0 0 0;
  }
}
article.hentry {
  .entry-header {
    .entry-title {
      font-size: 23px;
      font-weight: 600;
      color: #737373;
      margin: 0.67em 0;

      &:before {
        margin-right: 6px;
        color: #d82e16;
        font-size: 20px;
        font-weight: 600;
      }
    }

    hr {
      height: 1px;
      border: 0;
      background: #efefef;
      margin: 15px 0;
    }

    .breadcrumbs {
      font-size: 14px;
      color: #d2d2d2;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }
}
.courseImg{
  width: 40%;
}
</style>
